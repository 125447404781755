<template>
  <div>
    <my-nav-bar
      title="商品明细"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container list">
      <goods v-for="orderGoods in orderGoodsList" :key="orderGoods.id" :goods="orderGoods" />
    </div>
  </div>
</template>

<script>
import goods from './components/goods.vue'
import { getAfterGoodsList } from '@/api/bill_check'
import MyNavBar from '@/components/my-nav-bar'
export default {
  components: { goods, MyNavBar },
  data() {
    return {
      orderGoodsList: null
    }
  },
  created() {
    const params = {
      bill_id: this.$route.query.bill_id,
      after_order_id: this.$route.query.after_order_id
    }
    this.beginLoad()
    getAfterGoodsList(params).then(res => {
      this.endLoad()
      this.orderGoodsList = res.data
    })
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 15px 15px 5px 15px;
  }
</style>
